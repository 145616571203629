<template>
  <div class="user-content">
    <div class="scrollbar">
      <div class="bg-grey">
        <div class="nav">
          <span class="nav-item" @click="link('/')">首页 > </span>
          <span class="nav-item" @click="link('/order')">我的订单 > </span>
          <span>订单编号： {{ dataList.orderNo }}</span>
        </div>


        <div class="result">
          <div class="left">
            <div class="num">订单编号： {{ dataList.orderNo }}</div>
            <h3 v-if="dataList.orderStatus==1">
              <span class="iconfont icon-dengdai"></span>
              <span class="status">待支付</span>
              <el-button size="small" class="blue-btn" @click="pay">付款</el-button>
            </h3>
            <h3 v-else-if="dataList.orderStatus==2">
              <span class="iconfont icon-chenggong chenggong1"></span>
              <span class="status">已完成</span>
            </h3>
            <h3 v-else-if="dataList.orderStatus==3">
              <span class="iconfont icon-shibai"></span>
              <span class="status">已取消</span>
            </h3>
          </div>
          <div class="right">
            <div class="stream">
              <span class="iconfont icon-dingdan" :class="{info:dataList.orderStatus==3}"></span>
              <span class="stream-title">提交订单</span>
              <span class="create-time" v-if="dataList.orderStatus!=3">{{ toDate(dataList.createTime,'yyyy-MM-dd HH:mm:ss') }}</span>
              <div/>
            </div>
            <div class="line" :class="{info:dataList.orderStatus==1}"></div>
            <div class="stream" v-if="dataList.orderStatus==3">
              <span class="iconfont icon-_quxiaodingdan"></span>
              <span class="stream-title">取消处理</span>
              <div/>
            </div>
            <div class="stream" v-else>
              <span class="iconfont icon-yinhangqia1" :class="{info:dataList.orderStatus==1}"></span>
              <span class="stream-title">付款成功</span>
              <div/>
            </div>
            <div class="line" :class="{info:dataList.orderStatus==1}"></div>
            <div class="stream">
              <span class="iconfont icon-chenggong" :class="{info:dataList.orderStatus==1}"></span>
              <span class="stream-title">完成</span>
              <span class="create-time" v-if="dataList.orderStatus==2">{{ toDate(dataList.payTime,'yyyy-MM-dd HH:mm:ss') }}</span>
              <div/>
            </div>
          </div>
        </div>
        <!--订单详情-->
        <div class="detail-message">
          <div class="title">订单详情</div>
          <div class="table">
            <ul>
              <li class="table-item">
                <span class="item-title">交易订单</span>
                <span class="content">{{ dataList.orderNo }}</span>
              </li>
              <li class="table-item" v-if="dataList.payChannel">
                <span class="item-title">支付方式</span>
                <span class="content" v-if="dataList.orderStatus==2">{{ (dataList.payChannel == 'wx'||dataList.payChannel == '1') ? '微信' :(dataList.payChannel == 'alipay'||dataList.payChannel == 'wx') ?'支付宝':'' }}</span>
              </li>
              <li class="table-item">
                <span class="item-title">订单收款方式</span>
                <span class="content" v-if="dataList.orderStatus==2">{{ dataList.beneficiary == 1 ? '平台' : '商户收款平台' }}</span>
              </li>
              <li class="table-item">
                <span class="item-title">商品类型</span>
                <span class="content" v-if="dataList.goodsTypeCode==1001">赛事报名</span>
                <span class="content" v-else-if="dataList.goodsTypeCode==1002">培训报名</span>
                <span class="content" v-else-if="dataList.goodsTypeCode==1003">活动报名</span>
                <span class="content" v-else-if="dataList.goodsTypeCode==1004">会员申请</span>
                <span class="content" v-else-if="dataList.goodsTypeCode==1005">教学活动</span>
                <span class="content" v-else-if="dataList.goodsTypeCode==1011">考试报名</span>
<!--                <span class="content"-->
<!--                >{{-->
<!--                    dataList.goodsTypeCode == 100 ? '会员' : dataList.goodsTypeCode == 101 ? '竞赛' : dataList.goodsTypeCode == 103 ? '群众体育' : '培训'-->
<!--                  }}</span>-->
              </li>
              <li class="table-item">
                <span class="item-title">创建时间</span>
                <span class="content">{{ toDate(dataList.createTime,'yyyy-MM-dd HH:mm:ss') }}</span>
              </li>
              <li class="table-item" v-if="dataList.payTime">
                <span class="item-title">支付时间</span>
                <span class="content">{{ dataList.orderStatus == 2 ? toDate(dataList.payTime,'yyyy-MM-dd HH:mm:ss') : '' }}</span>
              </li>
              <li class="table-item" v-if="dataList.remark">
                <span class="item-title">订单备注</span>
                <span class="content">{{ dataList.remark ? dataList.remark : '' }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!--        table-->
        <div class="goods-detail">
          <div class="goods-table">
            <!--            表头-->
            <ul class="goods-title">
              <li class="title-item">
                {{
                  dataList.goodsTypeCode == 1001 ? '赛事名称' : dataList.goodsTypeCode == 1004 ? '会员名称' : dataList.goodsTypeCode == 103 ? '群众体育' : '培训名称'
                }}
              </li>
              <li class="title-item">单价</li>
              <li class="title-item">数量</li>
            </ul>
            <!--table内容-->

            <ul class="goods-content" v-for="(item,index) in goodsList" :key="index">
              <li class="content">
                <div class="content-item wrapper">
                  <img :src="loadUrl(item.goodsImg)" alt="">
                  <span class="s-title">{{ item.goodsTitle }}</span>
                </div>
                <div class="content-item">
                  <span>￥{{ item.goodsPrice }}</span>
                </div>
                <div class="content-item">
                  <span>x{{ item.goodsNum }}</span>
                </div>
                <div class="fold" v-if="item.showDetail" @click="fold(item)">
                  <span class="stream-title">收起</span>
                  <i class="el-icon-arrow-up"></i>
                </div>
                <div class="fold" v-else @click="fold(item,index)">
                  <span class="stream-title">展开</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </li>
              <EventsPerson v-if="item.showDetail && dataList.goodsTypeCode==1001" :eventsId="item.businessId" :orderNo="dataList.orderNo"></EventsPerson>
              <TrainPerson v-if="item.showDetail && (dataList.goodsTypeCode==1002||dataList.goodsTypeCode==1003)" :eventsId="item.businessId" :orderNo="dataList.orderNo"></TrainPerson>
<!--              <li class="person" v-if="personList[index] && item.showDetail && dataList.goodsTypeCode==100">-->
<!--                <span v-if="personList[index].memberType==='个人会员'">姓名：{{ personList[index].code.realName }}</span>-->
<!--                <span v-if="personList[index].memberType==='个人会员'">性别：{{ personList[index].code.sex }}</span>-->
<!--                <span v-if="personList[index].memberType==='个人会员'">证件号：{{ personList[index].code.id }}</span>-->
<!--                <span v-if="personList[index].memberType==='个人会员'">手机号：{{ personList[index].code.phone }}</span>-->
<!--                <span v-if="personList[index].memberType==='团体会员'">企业：{{ personList[index].code.fullName }}</span>-->
<!--                <span v-if="personList[index].memberType==='团体会员'">联系人：{{ personList[index].code.link }}</span>-->
<!--                <span v-if="personList[index].memberType==='团体会员'">企业：{{ personList[index].code.linkPhone }}</span>-->
<!--                <span>等级：{{ personList[index].memberLevel }}</span>-->
<!--                <span>申请时间：{{ toDate(personList[index].createTime) }}</span>-->
<!--              </li>-->
<!--              <li class="person" v-if="item.showDetail && dataList.goodsTypeCode==104">-->
<!--                <span>培训人员：{{ personList[index].name }}</span>-->
<!--                <span>性别：{{ personList[index].sex == 1 ? '男' : '女' }}</span>-->
<!--                <span>证件号：{{ personList[index].idNumber }}</span>-->
<!--                <span>手机号：{{ personList[index].phone }}</span>-->
<!--                <span>培训时间：{{personList[index].trainTime}}</span>-->
<!--                <span>培训地址：{{ personList[index].address }}</span>-->
<!--              </li>-->
<!--              <li class="person" v-if="  item.showDetail && dataList.goodsTypeCode==103">-->
<!--                <span>参赛会员：{{ personList[index].participant }}</span>-->
<!--                <span>性别：{{ personList[index].sex == 1 ? '男' : '女' }}</span>-->
<!--                <span>证件号：{{ personList[index].identityCode }}</span>-->
<!--                <span>手机号：{{ personList[index].phone }}</span>-->
<!--                <span>参赛项目：{{ personList[index].itemName }}</span>-->
<!--                <span>比赛时间：{{ toDate(personList[index].matchBegin) }} 至 {{ toDate(personList[index].matchEnd) }}</span>-->
<!--                <span>比赛地址：{{ personList[index].address }}</span>-->
<!--              </li>-->
            </ul>

            <div class="total-box">
              <div>商品总额：￥{{dataList.originalPrice}}</div>
              <div>会员优惠：-￥{{(dataList.originalPrice*100 - dataList.orderPrice*100)/100}}</div>
              <div class="red">{{dataList.orderStatus==1?'需':dataList.orderStatus==2?'实':'应'}}付款：￥{{dataList.orderPrice}}</div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <Pay v-if="payVisible" ref="Pay"/>
  </div>
</template>

<script>
import {getOrderListById} from '@/api/order'
import EventsPerson from '../component/eventsPerson'
import TrainPerson from '../component/trainPerson'
import Pay from "@/pages/payment/pay.vue";

export default {
  components:{EventsPerson,TrainPerson,Pay},
  data() {
    return {
      payVisible:false,
      orderNumber: this.$route.query.orderNumber,
      dataList: {},
      goodsList: [],
      personList: [],
      businessIdArr:[]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getOrderListById(this.orderNumber).then((res) => {
        if (res.code === 200) {
          this.dataList = res.data
          this.goodsList = res.data.goodsList
          this.businessIdArr = res.data.businessId.split(',')
          this.goodsList.map((item) => {
            this.$set(item, 'showDetail', false)
          })
        }
      })
    },
    link(path) {
      this.$router.push(path)
    },

    fold(item, index) {
      item.showDetail = !item.showDetail
      this.$forceUpdate() //！！！强刷页面必不可少
    },
    pay() {
      this.payVisible = true
      this.$nextTick(() => {
        this.$refs.Pay.init(this.orderNumber, 2);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.user-content {
  background: #F0F0F0;
}

.bg-grey {

  padding-right: 20px;

  .nav {
    color: #666;
    font-size: 12px;
    padding-bottom: 20px;

    .nav-item {
      cursor: pointer;
    }
  }

  .result {
    background-color: #fff;
    border-top: 2px solid #1E50AE;
    display: flex;
    width: 100%;
    font-size: 14px;

    .left {
      width: 262px;
      padding: 28px;
      border-right: 1px solid #EFF1F3;
      display: flex;
      //flex-grow: 1;
      flex-direction: column;
      //justify-content: center;
      align-items: center;

      .num {
        color: #999;
        padding-bottom: 35px;
      }

      h3 {
        margin-bottom: 20px;

        .status {
          font-size: 24px;
          line-height: 44px;
          font-weight: bold;
          color: #333333;
        }

        span {
          font-size: 44px;
          padding-right: 14px;
          vertical-align: middle;
        }

      }
    }

    .right {
      //flex-grow: 3;
      display: flex;
      padding: 40px 60px;
      justify-content: center;
      align-items: start;
      width: 100%;
      overflow: hidden;
      .stream {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        //width: 90px;
        //padding: 0 36px;

        .create-time {
          text-align: center;
          padding-top: 10px;
        }

        .icon-dingdan, .icon-_quxiaodingdan, .icon-chenggong, .icon-yinhangqia1 {
          font-size: 44px;
          vertical-align: middle;
          color: #5bc001;
          padding-bottom: 16px;

          &.info {
            color: #999999;
          }
        }
      }

      .line {
        flex-grow: 3;
        height: 2px;
        //width: 378px;
        width: 200px;
        background-color: #5bc001;
        margin-top: 23px;

        &.info {
          background-color: #999999;
        }
      }
    }
  }

  .detail-message {
    background-color: #fff;
    margin-top: 12px;
    padding: 20px;
    font-size: 14px;

    .title {
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }

    .table {
      color: #333333;

      ul {
        border: 1px solid #EFF1F3;
        list-style: none;

        li:first-child {
          border-top: none;
        }

        .table-item {
          border-top: 1px solid #EFF1F3;
          display: flex;
          align-items: center;

          .item-title {
            display: block;
            padding: 16px;
            margin-right: 24px;
            width: 145px;
            border-right: 1px solid #EFF1F3;
          }
        }
      }
    }
  }

  .goods-detail {
    background-color: #fff;
    margin-top: 12px;
    font-size: 14px;

    .goods-table {
      .goods-title {
        height: 44px;
        background: #F8F8F8;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0 20px;
        color: #555;

        .title-item {
          //flex-grow: 1;
          width: 33.3%;
          text-align: center;
        }
      }

      .goods-content {
        list-style: none;
        margin-bottom: 20px;

        .content {
          display: flex;
          position: relative;
          padding: 20px;
          justify-content: space-between;
          border-bottom: 1px solid #EFF1F3;
          color: #333;

          .content-item {
            //flex-grow: 1;
            width: 33.3%;
            text-align: center;
          }

          .wrapper {
            text-align: start;
            display: flex;
            align-items: start;

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              margin-right: 10px;
            }
          }

          .fold {
            position: absolute;
            right: 22px;
            bottom: 22px;
            font-size: 12px;
            color: #999999;
            cursor: pointer;
          }
        }
      }
      .total-box{
        text-align: right;
        margin-right: 20px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        line-height: 28px;
      }
      .person {
        padding: 20px;
        color: #999999;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #EFF1F3;

        span {
          padding: 10px 0;
        }
      }
    }
  }
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar-thumb{
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-track-piece{
  background-color: #F0F0F0;
}

.chenggong1 {
  color: #5bc001 !important;
}

.icon-dengdai {
  color: #FF8740 !important;
}
.icon-shibai{
  color: red !important;
}
.red{
  color: red !important;
  font-weight: bold;
}
.blue-btn {
  color: #3F6ABA;
  border: 1px solid #1E50AE;
  padding: 8px 30px;
  margin: 10px 50px 0;
}
</style>
