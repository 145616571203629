<template>
  <div>
    <el-table :data="dataList" v-loading="listLoading" style="width: 100%;">
      <el-table-column prop="name" label="参赛人员" width="100" align="center"/>
      <el-table-column prop="gender" label="性别" width="50" align="center">
        <template slot-scope="scope">{{scope.row.gender==1?'男':scope.row.gender==2?'女':''}}</template>
      </el-table-column>
      <el-table-column prop="certificatesNumber" label="证件号" width="170" align="center"/>
      <el-table-column prop="mobilePhone" label="手机号" width="120" align="center"/>
      <el-table-column prop="itemValName" label="参赛项目" align="center" width="180"/>
      <el-table-column label="比赛时间" align="center" width="190">
        <template slot-scope="scope">
          {{toDate(scope.row.gameStartTime,'yyyy-MM-dd')}}至{{toDate(scope.row.gameEndTime,'yyyy-MM-dd')}}
        </template>
      </el-table-column>
      <el-table-column label="比赛地址" align="center">
        <template slot-scope="scope">
          {{scope.row.province}}{{scope.row.city}}{{scope.row.area}}{{scope.row.address}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {competitionSignPersonnelGetApplyPersonList} from "../../api/events";

export default {
  name: "eventsPerson.vue",
  props:['eventsId','orderNo'],
  data(){
    return{
      dataList:[],
      params: {
        size: 1000,
        current: 1,
        name: '',
        competitionId:'',
        orderNo:''
      },
      listLoading:false
    }
  },
  created() {
    this.params.competitionId = this.eventsId
    this.params.orderNo = this.orderNo
    this.listLoading = true
    // this.$store.commit("showLoading");
    competitionSignPersonnelGetApplyPersonList(this.params).then(res=>{
      this.dataList = res.data.list
      this.listLoading = false
      // this.$store.commit("hideLoading");
    }).catch(()=>{
      this.listLoading = false
      // this.$store.commit("hideLoading");
    })
  }
}
</script>

<style scoped>

</style>
